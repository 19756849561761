<script setup lang="ts">
const { $api } = useNuxtApp()
const { t } = useI18n()
const { isMobile } = useDevice()
const { trackEvent } = useTracking()
const { createNotification } = useNotifications()
const { user } = useUser()

const props = withDefaults(
  defineProps<{
    destiId?: string | number | null
    inline?: boolean
    nocenter?: boolean
    notes?: string
    section: string
    showSuccessMessage?: boolean
  }>(),
  {
    destiId: null,
    inline: false,
    nocenter: false,
    notes: '',
    showSuccessMessage: true,
  },
)
const emits = defineEmits<{
  'show-input': [boolean]
}>()
defineSlots<{ button(): any }>()

const baseTelInput = ref(null)
const animateInput = ref(false)
const showInput = ref(false)
const showValidation = ref(false)
const success = ref(false)
const validNumber = ref(false)
const phoneValue = ref(user.value.phone || '')

const checkPhone = (valid: boolean) => {
  validNumber.value = Boolean(valid)
}

const sendToast = () => {
  createNotification({ message: t('global.valid_inquiry') })
}
const telHandle = () => {
  showValidation.value = false
}
const hiddenSuccess = () => {
  showValidation.value = false

  setTimeout(() => {
    success.value = false
  }, 300)
}
const showSuccess = () => {
  showInput.value = false
  success.value = true
  emits('show-input', showInput.value)

  setTimeout(() => {
    showValidation.value = true
  }, 300)
}
const callMeBack = () => {
  if (!animateInput.value) {
    hiddenSuccess()
    showInput.value = true
    emits('show-input', showInput.value)

    nextTick(() => {
      setTimeout(() => {
        animateInput.value = true
      }, 100)
    })
  } else {
    submitTailorNumber()
  }
}
const submitTailorNumber = async () => {
  if (validNumber.value) {
    let params: Record<string, unknown> = { phone: phoneValue.value }

    if (props.destiId) {
      params = {
        ...params,
        destination_id: Number(props.destiId),
      }
    }

    if (props.notes) {
      params = {
        ...params,
        notes: props.notes,
      }
    }

    await $api.v1.callbackRequests
      .create(params)
      .then(() => {
        showSuccess()
        animateInput.value = false

        if (!props.showSuccessMessage) {
          sendToast()
        }
        trackEvent({
          event: 'phone_number_sent',
          label: {
            section: props.section,
          },
          hash: {
            phone: phoneValue.value,
          },
        })
      })
      .catch((err: any) => {
        showValidation.value = true

        if (err.response?.data && err.response?.status === 400) {
          success.value = false
        }
      })
  }
}
const telInputClasses = computed(() => {
  let telInputClass = 'base-vue-tel-input'
  if (animateInput.value) {
    telInputClass += ' is-visible'
  }
  if (props.inline) {
    telInputClass += ' is-inline'
  }
  if (!props.inline) {
    telInputClass += ' w-full'
  }

  return telInputClass
})
</script>

<template>
  <form
    :class="['sliding-input', { nocenter }]"
    novalidate
    @submit.prevent="callMeBack"
  >
    <LazyBaseFormComponentsBaseTelInput
      v-if="showInput"
      ref="baseTelInput"
      v-model.trim="phoneValue"
      :aria-label="$t('global.ctaCallMe')"
      :id-form="section"
      :wrapper-class="telInputClasses"
      name="sliding-input"
      rules="required"
      @is-phone-valid="checkPhone"
      @update:model-value="telHandle"
    />

    <slot
      name="button"
      :show-input="showInput"
      :show-validation="showValidation"
      :valid-number="validNumber"
    >
      <base-button
        :block-full="isMobile"
        type="submit"
        :class="{ 'z-10': inline }"
        color="secondary"
      >
        {{ $t('global.ctaCallMe') }}
      </base-button>
    </slot>

    <div
      :class="[
        'sliding-input__validation',
        { 'is-visible': showValidation, 'is-inline': inline },
      ]"
    >
      <transition name="opacity">
        <p v-if="success && showSuccessMessage">
          {{ $t('global.valid_inquiry') }}
        </p>
      </transition>
    </div>
  </form>
</template>

<style>
.sliding-input {
  @apply relative z-30 text-center flex justify-center items-start flex-wrap;
}

.sliding-input.nocenter {
  @apply justify-start items-center;
}

.sliding-input__success-icon {
  @apply leading-loose ml-3 text-success;
}

.sliding-input .vti__dropdown {
  @apply static;
}

.sliding-input .vti__dropdown-list.below {
  top: 50px;
  @apply w-full;
}

.sliding-input .base-vue-tel-input {
  @apply flex mx-auto opacity-0 -z-1 rounded-sm;
  margin-bottom: -3.2rem;
  transition:
    margin 1s,
    opacity 1.5s;
}
.sliding-input .base-vue-tel-input.is-inline {
  @apply w-auto max-w-0 left-1 rounded-tr-none rounded-br-none m-0;
  border-right: none;
  transition:
    max-width 1s,
    border-width 0.2s 0.8s,
    opacity 0.2s 1s;
}
.sliding-input .base-vue-tel-input.is-visible {
  @apply mx-0 mb-4 opacity-100 z-base;
}
.sliding-input .base-vue-tel-input.is-visible:focus-within {
  @apply overflow-visible;
}
.sliding-input .base-vue-tel-input.is-visible.is-inline {
  transition:
    max-width 1s,
    border-width 0.2s,
    opacity 0.2s;
  @apply m-0 w-full max-w-full md:max-w-[270px] mb-4 md:mb-0;
}

.sliding-input .base-input {
  @apply h-[51px];
}
.sliding-input .base-vue-tel-input .vue-tel-input {
  @apply relative;
}
.sliding-input .base-vue-tel-input .vue-tel-input.is-invalid {
  @apply border-error;
}

.sliding-input__validation {
  @apply pointer-events-none opacity-0 -z-1 text-secondary-500 w-full;
  transition:
    margin 1s,
    opacity 1s;
}
.sliding-input__validation.is-visible {
  @apply opacity-100;
}
.sliding-input__validation.is-visible p {
  max-width: 500px;
  @apply mt-4 mx-auto;
}
.sliding-input__validation.is-visible.is-inline {
  transition:
    margin 1s,
    opacity 1s 0.3s;
}
</style>
